
import { defineComponent } from 'vue'
import store from '@/store/index'
import { ButtonGroup, IconButton } from '@/ui/index'
import { PresenceController, WorkbreakController, TimeEntryController, SessionController } from '@/controller/'
import { User, Presence, Workbreak, Employee, TimeEntry } from '@/model'
import { TimeEntryList } from '@/components'
import { ActiveWorkBar } from './components'
import { DateTimeUtils } from "@/utils/"

export default defineComponent({
    name: "Overview",
    components: { ButtonGroup, IconButton, ActiveWorkBar, TimeEntryList },
    data(){
        return {
            store,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            //activePresence: {} as Presence,
            activeBreak: {} as Workbreak,
            activeTimeEntry: {} as TimeEntry,
            timeEntries: [] as Array<TimeEntry> ,
            runningEntries: [] as Array<TimeEntry>,
            lastTimeEntries: [] as Array<TimeEntry>,
            presences: [] as Array<Presence>,
            workbreaks: [] as Array<Workbreak>,
            timeEntriesLoaded: false,
            presenceLoaded: false,
            breakLoaded: false,
            activeTimeTracked: '00:00 h',
            resetSwipe: false,
            activeDay: 0,
            today: 0,
            loading: {
                buttonCome: false,
                workBreak: false
            },
            daySum: 0,
            icons: {
                workbreak: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <line x1="2.8" y1="2.6" x2="2.8" y2="6.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <line x1="9.7" y1="2.6" x2="9.7" y2="6.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <line x1="6.3" y1=".9" x2="6.3" y2="6.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <path d="M11.3,14.5v-5.9s-10.2,0-10.2,0v5.9c0,1.4,1.2,2.6,2.6,2.6,0,0,5,0,5,0,1.4,0,2.6-1.2,2.6-2.6Z" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <path d="M11.7,14.2h.8c1.4,0,2.4-1.2,2.4-2.6v-1.4s-3.2,0-3.2,0" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>  </g></svg>',
                go: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <line x1="10.9" y1="9" x2="7.3" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>        <line x1="14.5" y1="9" x2="10.9" y2="9" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <polyline points="11.6 14.8 11.6 17 1.5 17 1.5 1 11.6 1 11.6 3.2" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>        <line x1="11.6" y1="6.1" x2="14.5" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>        <line x1="11.6" y1="11.9" x2="14.5" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>  </g></svg>',
                come: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>    <g>      <line x1="5.1" y1="9" x2="1.5" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>      <polyline points="4.4 3.2 4.4 1 14.5 1 14.5 17 4.4 17 4.4 14.8" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>      <line x1="8.7" y1="9" x2="5.1" y2="9" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/>      <line x1="5.8" y1="6.1" x2="8.7" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>      <line x1="5.8" y1="11.9" x2="8.7" y2="9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/>    </g>  </g></svg>',
                comego: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>          <g>        <g>          <line x1="12.4" y1="6.5" x2="10.8" y2="6.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>          <line x1="14.6" y1="6.5" x2="12.4" y2="6.5" fill="none" stroke="#1b1b1b" stroke-miterlimit="10" stroke-width="1"/>          <polyline points="6.5 5 6.5 1.5 12.8 1.5 12.8 2.9" fill="none" stroke="#1b1b1b" stroke-miterlimit="10" stroke-width="1"/>          <polyline points="12.8 10.1 12.8 11.5 10.7 11.5" fill="none" stroke="#1b1b1b" stroke-miterlimit="10" stroke-width="1"/>          <line x1="12.8" y1="4.7" x2="14.6" y2="6.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>          <line x1="12.8" y1="8.3" x2="14.6" y2="6.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>        </g>        <g>          <line x1="3.6" y1="11.5" x2="1.4" y2="11.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>          <polyline points="3.2 7.9 3.2 6.5 9.5 6.5 9.5 16.5 3.2 16.5 3.2 15.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10" stroke-width="1"/>          <line x1="5.9" y1="11.5" x2="3.6" y2="11.5" fill="none" stroke="#1b1b1b" stroke-miterlimit="10" stroke-width="1"/>          <line x1="4.1" y1="9.7" x2="5.9" y2="11.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>          <line x1="4.1" y1="13.3" x2="5.9" y2="11.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width="1"/>        </g>      </g>      </g></svg>',
                presence: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>          <g>        <circle cx="8" cy="9" r="6.7" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <polyline points="8 5.4 8 9 10.4 9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>      </g>      </g></svg>',
                worktime: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>      <path d="M1.2,15.9c.9-.9,1.6-1.6,1.6-1.6-.5-1.3-.2-2.8.9-3.9,1.1-1.1,2.6-1.3,3.9-.9l-2.6,2.6.4,1.9,1.9.4,2.6-2.6c.5,1.3.2,2.8-.9,3.9-1.1,1.1-2.6,1.3-3.9.9,0,0-.2.2-.5.5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>      <g>        <path d="M3,8.7c0-.4-.1-.8-.1-1.3,0-3.3,2.7-6,6-6s6,2.7,6,6-1.2,4.2-3.1,5.3" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <polyline points="8.8 4.2 8.8 7.4 11 7.4" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>      </g>  </g></svg>'
            }
        }
    },
    created(){
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
        this.today = this.activeDay
        this.getSessionUser()
        setTimeout(() => this.updateActiveTimeTracked(), 15000)
    },
    computed:{
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },
        readOnly() : boolean {
                //need to cast this - otherwise we get an error
                //Property 'activeDay' does not exist on type 'ComponentPublicInstance<{}, {}, {}, {}, {}, {}, {}, {}, false, ComponentOptionsBase<{}, {}, {}, {}, {}, ComponentOptionsMixin, ComponentOptionsMixin, {}, string, {}, {}, string>, {}>
            const activeDay = (this.activeDay as number)
            const today = (this.today as number)
            return today != activeDay
        },
        activePresence() {
            const pr = this.presences as Array<Presence>
            return pr.length ? pr[0] : null
        },        
        presenceTime() {
            const aP = (this.activePresence as Presence)
            if (!aP) {
                return 0
            }
            if (aP?.go) {
                return aP?.go - aP?.come
            } else {
                return Math.floor(new Date().valueOf() / 1000) - aP?.come
            }
        }        
        /* headline() : string {
            //const readonly = (this.readOnly as boolean)
            //const dDay = (this.displayActiveDay as string)
            //return  readonly ? (this.$t('work.yourWorksFor') + dDay) : this.$t('work.yourWorksToday')
            return this.$t('work.works') + '<span>' + dDay + '</span>'
        } */
    },
    methods:{
            //data
        async getSessionUser(){
            //this.loading = true
            this.sessionUser = SessionController.sessionUser 
            this.sessionEmployee = SessionController.sessionEmployee
            this.getEmployeeData()
        },
        async getTimeEntries() {
            this.timeEntriesLoaded = false
            //this.timeEntries = await TimeEntryController.fetchTimeEntries(this.sessionEmployee.uid)
            const resDay = await TimeEntryController.fetchDayTimeEntries(this.sessionEmployee.uid, this.activeDay)
            if(!resDay.error){
                this.timeEntries = resDay.items;
            } else {
                this.$notify(this.$t(resDay.error.message), { position: "right", type: "error" });
            }
            //[WS TODO] nur quick'n'dirty, damit ich laufenden von z.B. gestern habe -> sollte es ja dann nicht mehr geben
            const resRunning = await TimeEntryController.fetchRunningTimeEntries(this.sessionEmployee.uid)
            if(!resRunning.error){
                this.runningEntries = resRunning.items;
            } else {
                this.$notify(this.$t(resRunning.error.message), { position: "right", type: "error" });
            }
            //console.log("Overview:handleGetTimeEntries:timeEntries", this.timeEntries.length);
            this.lastTimeEntries = this.getLastTimeEntries()
            this.timeEntriesLoaded = true
            this.setDaySum()
            //this.getActiveWork()
            //this.loading = false
        },
        /* async handleCreateTimeEntry(){
            this.activeTimeEntry = await TimeEntryController.createTimeEntry(this.sessionEmployee.uid, 0, 0, 0, 0) as TimeEntry
        }, */
        async createClonedTimeEntry(entry: TimeEntry) {
            //createTimeEntry(employeeId:number, workId:number, projectId:number, customerId:number, workstationId:number) {            
                    //TODO replace startworkstation with the current one
            const entries = await TimeEntryController.createTimeEntry(entry.employee.uid, entry.work.uid, entry.project.uid, entry.customer.uid, entry.startWorkstation)
            if (entries?.error) {
                this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "error" })
            } else if (entries?.items) {
                this.updateTimeEntries(entries.items)
            }            
        },
        async toggleTimeEntry(entry: TimeEntry, startstop: string) {
            //console.log('toggleTimeEntry', entry, startstop);
            const { entries, workbreak } = await TimeEntryController.toggleTimeEntry(entry.uid, entry.employee.uid, entry.tstamp, startstop, entry.work.uid, entry.project?.uid, entry.customer?.uid, entry.startWorkstation)
            console.log('toggleTimeEntry entries / workbreak', entries, workbreak)
            if (entries?.error) {
                if (entries.error.data.error.code == 409) {
                    this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "warning" })
                    await this.createClonedTimeEntry(entry)
                } else {
                    this.$notify(this.$t(entries.error.data.error.message), { position: "right", type: "error" })
                }
            }
            if (entries?.items) {
                this.updateTimeEntries(entries.items)
            }
            if (workbreak.uid) {
                this.activeBreak = workbreak
            }
        },
        /* async getActivePresence() {
            const presences = await PresenceController.getActivePresence([this.sessionEmployee.uid])
            console.log('presences', presences)
            this.activePresence = presences.find((pr: any) => (pr?.employee?.uid == this.sessionEmployee.uid))
            this.presenceLoaded = true
        }, */
        async createPresence() {
            const presence = await PresenceController.createPresence(this.sessionEmployee)
            if (presence) {
                this.presences.push(presence);
            }
        },
        async endPresence(){
            const presence = await PresenceController.endPresence(this.sessionEmployee)
            if (presence) {
                const idx = this.presences.findIndex((pr: Presence) => pr.uid == presence.uid)
                if (idx != -1) this.presences.splice(idx, 1, presence)
            }
            if (this.activeTimeEntry?.start) {
                this.toggleTimeEntry(this.activeTimeEntry, 'stop')
            }            
        },
        async getActiveBreak(){
            const breaks = await WorkbreakController.getActiveWorkbreak([this.sessionEmployee.uid])
            this.activeBreak = breaks.find((br: any) => (br?.employee?.uid == this.sessionEmployee.uid))
            this.breakLoaded = true
        },        
        async createBreak(){
            this.loading.workBreak = true
            const {workbreak, timeentry} = await WorkbreakController.createWorkbreak(this.sessionEmployee)
            this.activeBreak = workbreak
            if (timeentry) this.updateTimeEntries([timeentry])
            /*if (this.activeTimeEntry?.start) {
                this.toggleTimeEntry(this.activeTimeEntry, 'stop')
            } */
            this.loading.workBreak = false
        },
        async updateBreak(){
            this.activeBreak = await WorkbreakController.stopWorkbreak(this.sessionEmployee)
        },
        async deleteTimeEntry(entry: TimeEntry){
            const deleted = await TimeEntryController.deleteTimeEntry(entry.uid, entry.tstamp)
            //console.log('deleteTimeEntry deleted', deleted)
            if (deleted?.items) this.removeTimeEntries(deleted.items)
        },
        async editTimeEntry(entry: TimeEntry){
            //this.activeTimeEntry = await TimeEntryController.deleteTimeEntry(entry.uid, entry.tstamp)
            this.$router.push('/edittimeentry/' + entry.getUid())
        },
        async getPresence() {
            const date = new Date()
            date.setTime(this.activeDay*1000)
            const day = DateTimeUtils.getMidnightToMidnight(date)
            const res = await PresenceController.getPresences(day.start, day.end, this.sessionEmployee.uid);
            if(!res.error){
                this.presences = res.items;
            } else {
                this.presences = []
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }         
            this.presenceLoaded = true    
        },
        async getWorkbreaks() {
            const response = await WorkbreakController.getDayWorkbreaks(this.sessionEmployee.uid, '', Math.floor(new Date().valueOf() / 1000))
            if (response.items) {
                this.workbreaks = response.items
            }
            console.log('getWorkbreaks', this.workbreaks)
        },   
            //functions
        getEmployeeData() {
            //this.getActivePresence()
            this.getPresence()
            this.getActiveBreak()
            this.getTimeEntries()
        },
        getLastTimeEntries(){
            if(this.timeEntries?.length){ 
                const sorted = this.timeEntries.sort((a : TimeEntry, b : TimeEntry) => b.start - a.start);
                const active = this.getActiveWork(sorted)
                if (active) {
                    this.activeTimeEntry = active
                    this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry?.getTimeTracked())
                } else {
                    this.activeTimeEntry = {} as TimeEntry
                    this.activeTimeTracked = ''
                }
                return sorted
            } /* else if (this.runningEntries?.length) { 
                    //[WS 2024-04-09] no longer valid anymore?
                    const active = this.getActiveWork(this.runningEntries)
                    this.activeTimeEntry = this.runningEntries[0];
                    this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry.getTimeTracked())
                    return this.runningEntries;
            }  */
            return []
        },
        getActiveWork(entries:Array<TimeEntry>) {
                //[WS TODO:] put this in controller or object and convert the startstops to an array
            const active = entries.find((entry : TimeEntry) => {
                let running = false;
                for (let key in entry.startstops) {
                    if (!running && entry.startstops[key].start != 0 && entry.startstops[key].stop == 0) running = true;
                }
                return running;
            });
            return active;
        },
        getProjectTitle(timeEntry: TimeEntry) {
            const p = timeEntry.project
            let title = p.numberExternal ? p.numberExternal : ''
            title += p.numberInternal ? (title ? ' / ' : '') + p.numberInternal : ''
            title += p.numberSerial ? (title ? ' / ' : '') + p.numberSerial : ''
            title += p.title ? (title ? ' ' : '') + p.title : ''
            return title
        },
        convertTimeStamp(ts:any){            
            return DateTimeUtils.convertTimeStampToTime(ts)
        },
        convertTimeStampToDateAndTime(ts:any){            
            return DateTimeUtils.convertTimeStampToDateAndTime(ts, false)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        updateTimeEntries(entries: any) {
                //TODO put this into controller and get the entries again from controller
            entries.forEach((entry: any) => {
                const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
                if (replaceIdx != -1) {
                    //console.log('replaceIdx', replaceIdx)
                    //console.log('entry', entry)
                    this.timeEntries.splice(replaceIdx, 1, entry)
                } else {
                    this.timeEntries.push(entry)
                }
            })
            this.lastTimeEntries = this.getLastTimeEntries()
        },
        removeTimeEntries(entries: any) {
                //TODO put this into controller and get the entries again from controller
            entries.forEach((entry: any) => {
                const replaceIdx = this.timeEntries.findIndex((e: any) => e.uid == entry.uid)
                if (replaceIdx != -1) {
                    console.log('replaceIdx', replaceIdx)
                    console.log('entry', entry)
                    this.timeEntries.splice(replaceIdx, 1)
                }
            })
            this.lastTimeEntries = this.getLastTimeEntries()
        },        
        updateActiveTimeTracked() {
            if (this.activeTimeEntry.uid) this.activeTimeTracked = DateTimeUtils.convertSecondsToTime(this.activeTimeEntry?.getTimeTracked())
            this.setDaySum()
            setTimeout(() => this.updateActiveTimeTracked(), 15000)  
        },
        setDaySum() {
            this.daySum = 0
            this.lastTimeEntries.forEach((entry: TimeEntry) => {
                this.daySum += entry.getTimeGone()
            });
        },

            //event handler
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                this.getEmployeeData()
                //this.getTimeEntries()
            }
        },       
        handlePresencePause(){
            if (this?.activeBreak?.stop == 0) {
                this.updateBreak();
            } else {
                this.createBreak();
            }
        },
        onTimeEntrySwipeLeft(timeEntry: TimeEntry) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteTimeEntryText' ), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.deleteTimeEntry(timeEntry) },
                onCancel: () => { 
                    this.resetSwipe = true
                    this.$nextTick(() => {
                        this.resetSwipe = false
                    })
                }
            });            
        },
        onTimeEntrySwipeRight(timeEntry: TimeEntry) {
            this.editTimeEntry(timeEntry)
        },
        onToggleTimeEntry(entry: TimeEntry, startstop: string) { 
            //console.log('handelToggleTimeEntry activeBreak', this.activeBreak)
            /* if (startstop == 'start' && this.activeBreak?.stop == 0) {
                this.updateBreak()
            } */
            this.toggleTimeEntry(entry, startstop)
        },
        onTimeupdate() {
            this.setDaySum()
        },
        handleOpenWorkForm(){
            this.$router.push('/addtimeentry/')
        },        
    }
})
